<template>
  <div>
    <v-container fluid>
      <c-ecosystem-nav :ecosystemId="ecosystemId"></c-ecosystem-nav>

      <v-row>
        <v-col id="listColumn" :style="{ height: stretch }">
          <v-container id="listContainer" :fluid="$vuetify.breakpoint.lgAndDown">
            <div v-if="$vuetify.breakpoint.smAndUp">
              <!-- Heading and action buttons -->
              <v-row :style="{ maxHeight: titleRowHeight }">
                <!-- Page heading -->
                <v-col offset-md="1" cols="12" :md="11">
                  <c-page-heading
                    :heading="$t('users.list.manage')"
                    :subHeading="$t('users.list.users')"
                  >
                  </c-page-heading>
                </v-col>
              </v-row>

              <!-- Page sub title -->
              <v-row>
                <v-col offset-md="1" cols="12" :md="7" class="text-left">
                  <div class="sub-title font-weight-light" data-cy="addNewUsersAndManageThemHere">
                    {{ $t('users.list.addNewUsersAndManageThemHere') }}
                  </div>
                </v-col>
              </v-row>
            </div>
            <div v-else class="mobile-title">
              <div class="top">
                {{ $t('users.list.manage') }} <span>{{ $t('users.list.users') }}</span>
              </div>
              <div class="bottom">{{ $t('users.list.addNewUsersAndManageThemHere') }}</div>
            </div>

            <!-- Loading spinner -->
            <v-row v-if="mounting" class="d-flex justify-center align-center" style="height: 50vh">
              <c-loading-spinner />
            </v-row>

            <!-- Search users and add/import buttons -->
            <v-row v-if="ecosystem.users && ecosystem.users.length" data-cy="user-filter">
              <!-- Table and desktop buttons and filters -->
              <v-col
                v-if="$vuetify.breakpoint.width >= 1140"
                offset-sm="1"
                cols="12"
                :sm="10"
                class="d-block d-md-flex justify-space-between align-center"
              >
                <div>
                  <c-search-input
                    v-on:search="setSearchTerm"
                    data-cy="search-input"
                    placeholder="Search for users..."
                  />
                </div>

                <div class="d-flex mt-4 mt-md-0">
                  <!-- User filters -->
                  <v-menu
                    v-model="showFilters"
                    bottom
                    :left="$vuetify.breakpoint.mdAndUp"
                    :right="$vuetify.breakpoint.smAndDown"
                    :close-on-content-click="false"
                    :nudge-bottom="54"
                    max-width="640px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-3 action-button white-button"
                        color="white"
                        v-bind="attrs"
                        v-on="on"
                        plain
                      >
                        <span class="filter-button-text">Filters</span>
                        <span v-if="alteredFilterColumns > 0" class="primary--text"
                          >&nbsp;({{ alteredFilterColumns }})</span
                        >

                        <v-icon size="24" class="filter-button-text ml-2"
                          >mdi-filter-variant</v-icon
                        >
                      </v-btn>
                    </template>

                    <c-user-filter-menu
                      v-on:filter="filter"
                      :filters="filters"
                      :neverFiltered="neverFiltered"
                      :unFiltered="unFiltered"
                    />
                  </v-menu>

                  <!-- Import button -->
                  <v-btn
                    class="action-button purple-button mr-3"
                    color="primary"
                    outlined
                    :to="{ path: `/ws/${ecosystemId}/users/import` }"
                    data-cy="import-user-btn"
                  >
                    Import users
                    <div class="ml-3 d-flex align-center">
                      <img style="width: 24px" src="/images/file-upload.png" alt="Import users" />
                    </div>
                  </v-btn>

                  <!-- Add new button -->
                  <v-btn
                    class="action-button purple-button justify-space-between pl-6 pr-3 py-3"
                    color="primary"
                    data-cy="add-user-btn"
                    @click="addNewUser"
                  >
                    <div class="pr-2">Add new user</div>
                    <v-icon size="24">mdi-plus</v-icon>
                  </v-btn>
                </div>
              </v-col>

              <!-- Mobile buttons and filters -->
              <v-col v-if="$vuetify.breakpoint.width < 1140">
                <v-btn
                  class="action-button purple-button mt-2"
                  color="primary"
                  @click="addNewUser"
                  data-cy="add-user-btn"
                >
                  Add new user
                  <v-icon size="24" class="ml-2">mdi-plus</v-icon>
                </v-btn>

                <div class="mt-6 mb-4 total-text">All users ({{ ecosystem.users.length }})</div>
                <div class="d-flex">
                  <c-search-input
                    v-on:search="setSearchTerm"
                    data-cy="search-input"
                    placeholder="Search for users..."
                  />
                  <button class="filter-square" @click="showFilters = true">
                    <div v-if="alteredFilterColumns > 0" class="rounded-circle filter-total">
                      {{ alteredFilterColumns }}
                    </div>
                    <v-icon size="24">mdi-filter-variant</v-icon>
                  </button>
                  <v-dialog v-model="showFilters">
                    <c-user-filter-menu
                      v-on:filter="filter"
                      v-on:close="showFilters = false"
                      :filters="filters"
                      :neverFiltered="neverFiltered"
                      :unFiltered="unFiltered"
                    />
                  </v-dialog>
                </div>
              </v-col>
            </v-row>

            <!-- Desktop user table -->
            <v-row v-if="$vuetify.breakpoint.width >= 1140 && visibleUsers && visibleUsers.length">
              <v-col
                offset-sm="1"
                cols="12"
                :sm="10"
                class="d-block d-md-flex justify-space-between align-center pb-0"
              >
                <c-user-table
                  :users="visibleUsers"
                  :ecosystemId="ecosystemId"
                  :selectAll="bulkSelectMode"
                  v-on:edit-user="editUser"
                  v-on:userChecked="userChecked"
                  v-on:toggleSelectAll="toggleSelectAll"
                />
              </v-col>
            </v-row>
            <!-- Mobile user list -->
            <div
              v-if="$vuetify.breakpoint.width < 1140 && visibleUsers && visibleUsers.length"
              class="user-card-list mt-4"
            >
              <c-user-card-small
                v-for="(user, index) in visibleUsers"
                :key="index"
                :user="user"
                :ecosystemId="ecosystemId"
                v-on:userChecked="userChecked"
                v-on:edit-user="editUser"
              ></c-user-card-small>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <!-- Bulk select panel -->
    <c-bulk-select-panel
      v-show="selectedUsers.length > 0"
      :quantitySelected="selectedUsers.length"
      :totalRecords="totalSelectable"
      :itemName="itemName"
      v-on:bulkAction="openDialog"
    />

    <!-- Confirm bulk delete users -->
    <c-confirm-delete-dialog
      :show="showDialog.remove"
      :quantity="usersToRemove.length"
      actionName="Remove"
      :itemName="itemName"
      v-on:decision="removeUsers"
      v-on:close="closeRemoveDialog"
    >
      <template v-if="iHadSelectedMyself" v-slot:question>
        You are trying to remove yourself
      </template>
      <template v-else v-slot:question>
        {{ $tc('users.remove.question', usersToRemove.length) }}
      </template>

      <template v-slot:detail>
        <div v-if="iHadSelectedMyself">
          We wouldn’t want to do that as your ecosystem would be lost, so we have deselected you
          <br />
          The selected users will only be removed from this ecoystem. <br />
          Their account will not be deleted from the database
        </div>
        <div v-else>
          They will only be removed from this ecoystem. <br />
          Their account will not be deleted from the database
        </div>
      </template>
    </c-confirm-delete-dialog>

    <!-- Set default user space dialog -->
    <c-set-default-user-space-dialog
      :ecosystemId="ecosystemId"
      :show="showDialog.setDefaultSpace"
      :selectedUsers="selectedUsers"
      v-on:apply="setDefaultSpace"
      v-on:close="showDialog.setDefaultSpace = false"
    />

    <!-- Set new role dialog -->
    <c-set-new-user-role-dialog
      :show="showDialog.setNewRole"
      :selectedUsers="selectedUsers"
      v-on:apply="setNewRole"
      v-on:close="showDialog.setNewRole = false"
    >
      <template v-if="iHadSelectedMyself" v-slot:instructions>
        You can't change your own role, so we have deselected you <br /><br />
        Choose new role for selected users
      </template>
    </c-set-new-user-role-dialog>

    <!-- User upsert dialog -->
    <c-upsert-modal
      title="User details"
      :show="showDialog.userUpsert"
      :submitBtnText="userUpsertSubmitBtnText"
      :showDeleteBtn="!dialogUser.isNew && dialogUser.id !== $store.getters['auth/user'].id"
      deleteBtnText="Remove"
      :disableSubmit="!validUserUpsert"
      v-on:submit="submitUpsert"
      v-on:delete="removeUser"
      v-on:close="closeUserUpsert"
    >
      <c-user-upsert-content
        title="Provide the user details."
        :user="dialogUser"
        :ecosystemId="ecosystemId"
        v-on:validate="validUserUpsert = $event"
      />
    </c-upsert-modal>

    <c-loading-overlay v-if="loading" />
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid'
import { sleep } from '../../utilities/functions'
const _ = require('lodash')

export default {
  props: ['ecosystemId'],
  data: () => ({
    searchTerm: '',
    ecosystem: {},
    visibleUsers: [],
    windowWidth: window.innerWidth,
    mounting: false,
    loading: false,
    showDialog: {
      remove: false,
      setDefaultSpace: false,
      setNewRole: false,
      userUpsert: false,
    },
    selectedUsers: [],
    iHadSelectedMyself: false,
    showFilters: false,
    neverFiltered: true,
    usersToRemove: [],
    filters: {
      type: {
        mesmerise: true,
        external: true,
      },
      avatar: 'All',
      role: {
        guest: true,
        user: true,
        admin: true,
      },
    },
    bulkSelectMode: false,
    dialogUser: {},
    validUserUpsert: false,
  }),
  computed: {
    iconSize() {
      return this.windowWidth <= 420 ? '15px' : '20px'
    },
    btnSize() {
      return this.windowWidth <= 420 ? '30px' : '36px'
    },
    userDetailsTextSize() {
      return this.windowWidth <= 420 ? '1rem' : '1.3rem'
    },
    titleRowHeight() {
      return '120px'
    },
    largeScreen() {
      return this.windowWidth <= 470 ? false : true
    },
    showSheet() {
      return this.selectedUsers.length > 0
    },
    itemName() {
      return this.selectedUsers.length > 1 ? 'users' : 'user'
    },
    screenHeight() {
      return screen.height
    },
    stretch() {
      if (
        (this.windowHeight > 800 &&
          this.windowWidth > 700 &&
          this.windowWidth < 1260 &&
          this.visibleUsers.length < 3) ||
        (this.windowHeight > 800 &&
          this.windowWidth >= 1260 &&
          this.windowWidth < 1600 &&
          this.visibleUsers.length < 4) ||
        (this.windowHeight > 800 && this.windowWidth >= 1600 && this.visibleUsers.length < 5)
      ) {
        return 'calc(100vh - 280px)'
      }
      return null
    },
    unFiltered() {
      return this.alteredFilterColumns === 0
    },
    alteredFilterColumns() {
      let count = 0

      if (!this.filters.type.mesmerise || !this.filters.type.external) {
        count++
      }

      if (this.filters.avatar !== 'All') {
        count++
      }

      if (!this.filters.role.guest || !this.filters.role.user || !this.filters.role.admin) {
        count++
      }

      return count
    },
    totalSelectable() {
      return this.workspace?.users?.length ?? undefined
    },
    userUpsertSubmitBtnText() {
      return this.dialogUser.isNew ? 'Add user' : 'Update user'
    },
  },
  asyncComputed: {
    iSelectedMyself() {
      let myId = this.$store.getters['auth/user'].id
      return this.selectedUsers.filter(user => user.id === myId)[0]
    },
  },
  watch: {
    searchTerm(string) {
      const words = string.trim().split(' ')

      // If no search term show all users
      if (!string || words.length === 0) {
        this.visibleUsers = this.mapUsers(this.ecosystem.users)
        return
      }

      this.visibleUsers = []

      for (const user of this.ecosystem.users) {
        for (const w of words) {
          let word = w.toLowerCase()
          if (
            user.givenName?.toLowerCase().includes(word) ||
            user.surname?.toLowerCase().includes(word) ||
            user.email?.toLowerCase().includes(word)
          ) {
            let isSelected = this.selectedUsers.find(sUser => sUser.id === user.id) ?? false
            this.visibleUsers.push({ ...user, checkBox: { show: true, value: isSelected } })
            break
          }
        }
      }
    },
    ecosystem: {
      handler(newValue) {
        this.visibleUsers = this.mapUsers(newValue.users)
      },
      deep: true,
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })

    this.mounting = true
    this.dialogUser = this.defaultUser()
    this.setUp()
  },
  methods: {
    setUp() {
      this.bulkSelectMode = false
      this.$store.dispatch('ecosystems/fetchById', this.ecosystemId).then(() => {
        this.$store.dispatch('ecosystems/fetchUsers', this.ecosystemId).then(() => {
          this.ecosystem = this.$store.getters['ecosystems/getById'](this.ecosystemId)
          this.visibleUsers = this.mapUsers(this.ecosystem.users)
          this.mounting = false
        })
      })
    },
    defaultUser() {
      return {
        id: uuidv4(),
        givenName: null,
        surname: null,
        email: null,
        company: null,
        jobTitle: null,
        userTimeZone: null,
        defaultGatheringId: null,
        userType: 2,
        isNew: true,
      }
    },
    mapUsers(usersToMap) {
      let mappedUsers = []
      usersToMap.forEach(user => {
        let isSelected = this.selectedUsers.find(sUser => sUser.id === user.id) ?? false
        mappedUsers.push({ ...user, checkBox: { show: true, value: isSelected } })
      })
      return mappedUsers
    },
    async openDialog(type, selectedUsers = null) {
      if (['remove', 'setNewRole'].includes(type) && this.iSelectedMyself) {
        await this.deselectMe()
        this.iHadSelectedMyself = true
      }

      if (type === 'remove') {
        // Use the users manually passed in or the selected users
        this.usersToRemove = [].concat(selectedUsers || this.selectedUsers)
      }

      this.showDialog[type] = true
    },
    async deselectMe() {
      let myId = await this.$store.getters['auth/user'].id
      this.selectedUsers = this.selectedUsers.filter(user => user.id !== myId)
      this.visibleUsers = this.visibleUsers.map(user => {
        return user.id !== myId ? user : { ...user, checkBox: { show: true, value: false } }
      })
    },
    removeUser() {
      // Open the dialog with the current users
      this.openDialog('remove', this.dialogUser)
    },
    async removeUsers(decision) {
      if (decision === 'no') {
        this.closeRemoveDialog()
        return
      }

      await this.$store.dispatch('ecosystems/removeUsers', {
        ecosystemId: this.ecosystemId,
        users: this.usersToRemove,
      })

      this.ecosystem = await this.$store.getters['ecosystems/getById'](this.ecosystemId)
      this.usersToRemove = []
      this.closeRemoveDialog()
      this.showDialog.userUpsert = false
      this.showSuccessSnackBar('Removed')
    },
    userChecked({ user, value }) {
      if (value === true) {
        this.selectedUsers.push(user)
      } else {
        this.selectedUsers = this.selectedUsers.filter(u => {
          return u.id !== user.id
        })
      }

      const index = this.visibleUsers.map(item => item.id).indexOf(user.id)
      this.visibleUsers.splice(index, 1, { ...user, checkBox: { show: true, value } })
    },
    toggleSelectAll(value) {
      if (value === false) {
        this.deselectUsers()
        this.bulkSelectMode = false
        return
      }

      this.bulkSelectMode = true
      this.selectedUsers = _.cloneDeep(this.ecosystem.users)
      this.visibleUsers = this.ecosystem.users.map(user => {
        return { ...user, checkBox: { show: true, value: true } }
      })
    },
    deselectUsers() {
      this.visibleUsers = this.visibleUsers.map(vUser => {
        return { ...vUser, checkBox: { show: true, value: false } }
      })
      this.selectedUsers = []
    },
    async setDefaultSpace(selectedSpace) {
      this.showDialog.setDefaultSpace = false
      this.loading = true

      try {
        for (const user of this.selectedUsers) {
          await this.$store.dispatch('ecosystems/updateUser', {
            ecosystemId: this.ecosystemId,
            userId: user.id,
            data: {
              ecosystemId: this.ecosystemId,
              user: {
                id: user.id,
                defaultGatheringId: selectedSpace.id,
                givenName: user.givenName,
                surname: user.surname,
                email: user.email,
              },
              userType: user.userType,
            },
          })
        }
      } catch (error) {
        let message = (error.response && error.response.data) || error.message || error.toString()
        this.deselectUsers()
        this.loading = false
        this.setUp()
        this.$store.dispatch('ui/launchSnackbar', {
          color: 'red',
          message,
          buttonColor: 'white',
        })

        return
      }

      this.setUp()
      this.showSuccessSnackBar()
    },
    async setNewRole(selectedRole) {
      this.showDialog.setNewRole = false

      this.loading = true

      try {
        for (const user of this.selectedUsers) {
          await this.$store.dispatch('ecosystems/updateUser', {
            ecosystemId: this.ecosystemId,
            userId: user.id,
            data: {
              ecosystemId: this.ecosystemId,
              user: {
                id: user.id,
                defaultGatheringId: user.defaultGatheringId,
                givenName: user.givenName,
                surname: user.surname,
                email: user.email,
              },
              userType: selectedRole,
            },
          })
        }
      } catch (error) {
        let message = (error.response && error.response.data) || error.message || error.toString()
        this.deselectUsers()
        this.loading = false
        this.$store.dispatch('ui/launchSnackbar', {
          color: 'red',
          message,
          buttonColor: 'white',
        })

        return
      }

      this.setUp()
      this.showSuccessSnackBar()
    },
    showSuccessSnackBar(action = 'updated') {
      let userString = this.selectedUsers.length > 1 ? 'Users' : 'User'
      this.deselectUsers()

      this.loading = false
      this.$store.dispatch('ui/launchSnackbar', {
        color: 'green',
        message: `${userString} ${action}`, // eg 'users updated' or 'user deleted' etc.
        buttonColor: 'white',
      })
    },
    setSearchTerm(searchTerm) {
      this.searchTerm = searchTerm
      this.search()
      this.filter()
    },
    search() {
      if (!this.searchTerm.trim().length) {
        this.visibleUsers = this.mapUsers(this.ecosystem.users)
        return
      }
      // filter users for those that case insensitively match the term
      // assign these filtered users as visible users
      const words = this.searchTerm.trim().split(' ')

      if (words.length === 0) {
        this.visibleUsers = this.mapUsers(this.ecosystem.users)
        return
      }

      this.visibleUsers = []

      for (const user of this.ecosystem.users) {
        for (const w of words) {
          let word = w.toLowerCase()
          if (
            user.givenName.toLowerCase().includes(word) ||
            user.surname.toLowerCase().includes(word) ||
            user.email.toLowerCase().includes(word)
          ) {
            this.visibleUsers.push(this.mapUsers([user])[0])
            break
          }
        }
      }
    },
    filter() {
      this.showFilters = false
      this.neverFiltered = false

      // Reset the visible users to those subject to the search term
      this.search()

      // filter the visible users

      // by type, ie by email
      if (this.filters.type.mesmerise !== this.filters.type.external) {
        let mesmeriseOnly = this.filters.type.mesmerise
        let internalDomains = ['mesmerisevr.com', 'mesmerise.it']
        if (mesmeriseOnly) {
          this.visibleUsers = this.visibleUsers.filter(user =>
            internalDomains.includes(user.email.substr(user.email.indexOf('@') + 1))
          )
        } else {
          this.visibleUsers = this.visibleUsers.filter(
            user => !internalDomains.includes(user.email.substr(user.email.indexOf('@') + 1))
          )
        }
      }

      // by avatar
      if (this.filters.avatar !== 'All') {
        if (this.filters.avatar === 'With avatar') {
          this.visibleUsers = this.visibleUsers.filter(user =>
            user.activeAvatarThumbnail.includes('https')
          )
        }
        if (this.filters.avatar === 'Without avatar') {
          this.visibleUsers = this.visibleUsers.filter(
            user => !user.activeAvatarThumbnail.includes('https')
          )
        }
      }

      // by role
      if (this.filters.role.guest || this.filters.role.user || this.filters.role.admin) {
        let roles = []
        if (this.filters.role.guest) {
          roles.push(4)
        }
        if (this.filters.role.admin) {
          roles.push(2)
        }
        if (this.filters.role.user) {
          roles.push(0)
        }
        this.visibleUsers = this.visibleUsers.filter(user => roles.includes(user.userType))
      }
    },
    addNewUser() {
      this.dialogUser = this.defaultUser()
      this.openDialog('userUpsert')
    },
    editUser(user) {
      this.dialogUser = {
        ..._.pick(
          user,
          'id',
          'givenName',
          'surname',
          'email',
          'company',
          'jobTitle',
          'userTimeZone',
          'defaultGatheringId',
          'userType'
        ),
        isNew: false,
      }
      this.openDialog('userUpsert')
    },
    submitUpsert() {
      if (!this.validUserUpsert) {
        return
      }

      this.$store.dispatch('ui/toggleLoading', { isLoading: true })

      const { userType, ...dialogUser } = this.dialogUser
      const data = {
        ecosystemId: this.ecosystemId,
        user: { ...dialogUser, userTimeZone: dialogUser.userTimeZone },
        userType,
      }

      if (!this.dialogUser.isNew) {
        this.updateUser(data)
      } else {
        this.createUser(data)
      }
    },
    createUser(data) {
      this.$store.dispatch('ecosystems/addUser', { ecosystemId: this.ecosystemId, data }).then(
        () => {
          const params = {
            color: 'green',
            message: 'User added',
            buttonColor: 'white',
          }
          this.$store.dispatch('ui/launchSnackbar', params)
          this.$store.dispatch('ui/toggleLoading', { isLoading: false })
          this.closeUserUpsert()
        },
        error => {
          this.$store.dispatch('ui/toggleLoading', { isLoading: false })
          this.closeUserUpsert()
          this.message =
            (error.response && error.response.data) || error.message || error.toString()
        }
      )
    },
    updateUser(data) {
      this.$store
        .dispatch('ecosystems/updateUser', {
          ecosystemId: this.ecosystemId,
          userId: this.dialogUser.id,
          data: data,
        })
        .then(
          () => {
            const params = {
              color: 'green',
              message: 'User updated',
              buttonColor: 'white',
            }
            this.$store.dispatch('ui/launchSnackbar', params)
            this.$store.dispatch('ui/toggleLoading', { isLoading: false })
            this.closeUserUpsert()
          },
          error => {
            this.$store.dispatch('ui/toggleLoading', { isLoading: false })
            this.closeUserUpsert()
            this.message =
              (error.response && error.response.data) || error.message || error.toString()
          }
        )
    },
    closeUserUpsert() {
      this.showDialog.userUpsert = false
      sleep(200)
      this.dialogUser = this.defaultUser()
    },
    closeRemoveDialog() {
      // the response from updating a user messes up the user data by removing things like the avatar url, so refetch the workspace from the API
      this.setUp()
      this.showDialog.remove = false
      this.iHadSelectedMyself = false

      this.usersToRemove = []
    },
  },
}
</script>
<style scoped lang="scss">
.sub-title {
  font-size: 1.3rem;
}

.user-row {
  font-size: 1.3rem;
  border-top: 1px dotted #cbc9f7;
  line-height: 1.5em;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 4px 34px -1px rgb(75 67 168 / 16%);
}

.action-button {
  align-items: center;
  border-radius: 8px !important;
  display: flex;
  flex-direction: row;
  font-size: 16px !important;
  font-style: normal !important;
  justify-content: center;
  line-height: 24px !important;
  text-transform: none;
  width: 100%;
  height: 48px !important;
}

@media screen and (min-width: 600px) {
  .action-button {
    width: auto;
  }
}

.purple-button {
  font-weight: 700;
  gap: 8px;
  height: 48px !important;
}

.white-button {
  background-color: #ffffff;
  border: 1px solid #e2e5e9 !important;
  gap: 6px;
  padding: 12px 12px 12px 16px !important;
  width: 100%;
  height: 50px !important;
}

@media screen and (min-width: 600px) {
  .white-button {
    width: auto;
  }
}

.white-button:focus {
  outline: 1px solid #807ef0;
}

.filter-button-text {
  color: #252729 !important;
  font-weight: 400 !important;
}

.total-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #1f2329;
}

.filter-square {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 6px;
  width: 48px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #dce2e9;
  border-radius: 8px;
  margin-left: 12px;
  position: relative;
}

@media screen and (min-width: 600px) {
  .v-menu__content {
    border-radius: 8px;
    border: 1px solid #eff1f3;
    box-shadow: 0px 10px 25px rgba(41, 45, 51, 0.12);
  }
}

::v-deep .v-dialog {
  border-radius: 16px;
  overflow: scroll;
}

.filter-total {
  background: #807ef0;
  width: 20px;
  height: 20px;
  position: absolute;
  color: white;
  top: -1px;
  left: 30px;
}

.header-text {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #95a1aa;
}

.sorting-icon {
  width: 24px;
  height: 24px;

  img {
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 599px) {
  #listContainer {
    padding: 0;
  }
}

@media screen and (max-width: 599px) {
  #listColumn {
    padding: 16px !important;
  }
}

.mobile-title {
  margin: 0px 0px 8px 0px;
  font-style: normal;
  color: #1f2329;
}

.mobile-title .top {
  font-weight: 400;
  font-size: 20px !important;
  line-height: 32px;
  margin-bottom: 2px;
}

.mobile-title .top span {
  font-weight: 600;
}

.mobile-title .bottom {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

.user-card-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
}
</style>
